
<template v-slot:default>
  <div>
    <toolbarTitle title="Passengers" />

    <div class="elevation-1 rounded">
      <v-data-table :headers="headers" :items="passengers" hide-default-footer>
        <template v-slot:[`item.firstName`]="{ item }">
          <div class="font-weight-bold">
            {{ item.firstName }} {{ item.lastName }}
          </div>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div class="font-weight-bold">
            {{ item.email ? item.email : "N/A" }}
          </div>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div class="font-weight-bold">
            {{ item.phone ? item.phone : "N/A" }}
          </div>
        </template>
        <template v-slot:[`item.birthdate`]="{ item }">
          <div class="font-weight-bold">
            {{ item.birthdate ? item.birthdate : "N/A" }}
          </div>
        </template>
        <template v-slot:[`item.countryOfResidence`]="{ item }">
          <div class="font-weight-bold">
            {{ item.countryOfResidence ? item.countryOfResidence : "N/A" }}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>



<script>
import toolbarTitle from "@/components/common/toolbarTitle";
export default {
  name: "Passengers",
  components: {
    toolbarTitle,
  },
  props: {
    passengers: Array,
  },

  mounted() {
    this.passengersData = this.passengers;
  },
  data: () => ({
    passengersData: [],
    headers: [
      {
        text: "Full Name",
        value: "firstName",
        sortable: false,
      },
      {
        text: "E-Mail",
        value: "email",
        sortable: false,
      },
      {
        text: "Phone",
        value: "phone",
        sortable: false,
      },
      {
        text: "Birthdate",
        value: "birthdate",
        sortable: false,
      },
      {
        text: "Country",
        value: "countryOfResidence",
        sortable: false,
      },
    ],
  }),
};
</script>
<style>
</style>
