var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.formValid,
      callback: function ($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_vm.customer ? _c('v-expansion-panels', {
    attrs: {
      "value": 0,
      "accordion": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v(" Customer Information ")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.firstName,
      "label": "Firstname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "firstName", $$v);
      },
      expression: "customer.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.lastName,
      "label": "Lastname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "lastName", $$v);
      },
      expression: "customer.lastName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.phone,
      "label": "Phone",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "phone", $$v);
      },
      expression: "customer.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.validate.email,
      "label": "Email",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.email,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "email", $$v);
      },
      expression: "customer.email"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "accent",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1), _vm.customer.delivery ? _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v("Shipping address")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.firstName,
      "label": "Firstname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "firstName", $$v);
      },
      expression: "customer.delivery.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.lastName,
      "label": "Lastname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "lastName", $$v);
      },
      expression: "customer.delivery.lastName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.phone,
      "label": "Phone",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.phone,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "phone", $$v);
      },
      expression: "customer.delivery.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.customer.delivery.email,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "email", $$v);
      },
      expression: "customer.delivery.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Address",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.address,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "address", $$v);
      },
      expression: "customer.delivery.address"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.address2,
      "label": "Address 2",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.address2,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "address2", $$v);
      },
      expression: "customer.delivery.address2"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.region,
      "label": "Region",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.region,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "region", $$v);
      },
      expression: "customer.delivery.region"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.postalCode,
      "label": "Postal Code",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "postalCode", $$v);
      },
      expression: "customer.delivery.postalCode"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.delivery.city,
      "label": "City",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.city,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "city", $$v);
      },
      expression: "customer.delivery.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.countries,
      "clearable": "",
      "hide-details": "",
      "return-object": "",
      "label": "Country",
      "outlined": ""
    },
    model: {
      value: _vm.customer.delivery.country,
      callback: function ($$v) {
        _vm.$set(_vm.customer.delivery, "country", $$v);
      },
      expression: "customer.delivery.country"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "accent",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.customer.billing ? _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v(" Billing address ")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.firstName,
      "label": "Firstname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "firstName", $$v);
      },
      expression: "customer.billing.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.lastName,
      "label": "Lastname",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "lastName", $$v);
      },
      expression: "customer.billing.lastName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.phone,
      "label": "Phone",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.phone,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "phone", $$v);
      },
      expression: "customer.billing.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.customer.billing.email,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "email", $$v);
      },
      expression: "customer.billing.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.address,
      "label": "Address",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.address,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "address", $$v);
      },
      expression: "customer.billing.address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.address2,
      "label": "Address 2",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.address2,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "address2", $$v);
      },
      expression: "customer.billing.address2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.region,
      "label": "Region",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.region,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "region", $$v);
      },
      expression: "customer.billing.region"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.postalCode,
      "label": "Postal Code",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "postalCode", $$v);
      },
      expression: "customer.billing.postalCode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.customer.billing.city,
      "label": "City",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.city,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "city", $$v);
      },
      expression: "customer.billing.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.countries,
      "clearable": "",
      "hide-details": "",
      "return-object": "",
      "label": "Country",
      "outlined": ""
    },
    model: {
      value: _vm.customer.billing.country,
      callback: function ($$v) {
        _vm.$set(_vm.customer.billing, "country", $$v);
      },
      expression: "customer.billing.country"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "accent",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1) : _vm._e(), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v(" Payment information")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Bank Name",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.customerPayment.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.customerPayment, "bankName", $$v);
      },
      expression: "customerPayment.bankName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Clearing Number",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.customerPayment.clearingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.customerPayment, "clearingNumber", $$v);
      },
      expression: "customerPayment.clearingNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Account Number",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.customerPayment.accountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.customerPayment, "accountNumber", $$v);
      },
      expression: "customerPayment.accountNumber"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "accent",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }