var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    attrs: {
      "title": "Passengers"
    }
  }), _c('div', {
    staticClass: "elevation-1 rounded"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.passengers,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.firstName`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " ")])];
      }
    }, {
      key: `item.email`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.email ? item.email : "N/A") + " ")])];
      }
    }, {
      key: `item.phone`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.phone ? item.phone : "N/A") + " ")])];
      }
    }, {
      key: `item.birthdate`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.birthdate ? item.birthdate : "N/A") + " ")])];
      }
    }, {
      key: `item.countryOfResidence`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.countryOfResidence ? item.countryOfResidence : "N/A") + " ")])];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }