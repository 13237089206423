<template>
  <div>
    <h3 class="mt-3">Select the distributions you want to add to the order.</h3>
    <span>You may only select the distribution provided by tenant which is registered in order. </span>
    <v-form ref="distributionForm" v-model="form.valid" :loading="isLoading" :disabled="isLoading">
      <v-row class="mt-4">
        <v-col cols="12">
          <v-combobox
            v-if="distributions.length > 0"
            label="Distributions"
            v-model="form.distributions"
            :items="distributions"
            item-text="title"
            item-value="slug"
            small-chips
            hide-details
            multiple
            outlined>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
                <span class="pr-2">
                  {{ item.title }}
                </span>
                <v-icon small color="primary" @click="parent.selectItem(item)"> $delete </v-icon>
              </v-chip>
            </template>
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span
                  >Add additional distribution to the order. For flexible priced distributions, you can leave the price
                  boxes below empty and the system will choose the correct price depending on if the order is
                  international or domestic.</span
                >
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="11">
          <v-list v-if="form.distributions.length > 0">
            <v-list-item
              :class="'d-flex align-center py-1 px-3 rounded border999 ' + (i < 1 ? 'mt-2' : 'mt-4')"
              v-for="(item, i) in form.distributions"
              :key="i">
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <b v-if="!item.flexiblePrice" style="font-size: 20px; width: 100px"
                >{{
                  item.prices
                    .filter((r) => r.currency === currency)
                    .map((p) => p.amount)
                    .join("/")
                }}
                {{ currency }}
              </b>

              <v-text-field
                v-if="item.flexiblePrice"
                :disabled="!item.flexiblePrice"
                hide-details
                :label="
                  item.prices
                    .filter((r) => r.currency === currency)
                    .map((p) => p.amount)
                    .join('/')
                "
                v-model.number="item.setPrice"
                hint="Price"
                outlined
                class="font-weight-bold"
                type="number"
                dense></v-text-field>
              <span
                v-if="item.flexiblePrice"
                style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%)"
                class="currency">
                <b style="font-size: 16px">{{ currency }}</b>
              </span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-form>
    <v-btn :loading="isLoading" class="mt-3" color="accent" style="margin-left: 390px" @click="saveDistributions()">
      Save
    </v-btn>
  </div>
</template>
<script>
import { validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api.js";

export default {
  name: "DistributionForm",
  data() {
    return {
      isValid: false,
      isLoading: false,
      form: {
        valid: false,
        distributions: [],
      },
      distributions: [],
      validate: {
        greater: validator.greaterZeroCheck(),
      },
    };
  },
  props: {
    tenantId: {
      type: String,
      default: "",
      required: true,
    },
    currency: {
      type: String,
      default: "",
      required: true,
    },
    registeredDistributions: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: String,
      default: "",
      required: true,
    },
    locale: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getDistributions();
  },
  watch: {
    "form.distributions": {
      handler(newValue) {
        this.isValid = newValue.length > 0;
      },
      deep: true,
    },
  },
  methods: {
    async saveDistributions() {
      this.isLoading = true;
      try {
        if (this.$refs[`distributionForm`].validate()) {
          let distributions = [];
          this.form.distributions.forEach((item) => {
            let obj = { slug: item.slug, prices: item.prices };
            distributions.push(obj);
          });
          const res = await Trainplanet.addItems(this.orderId, { distributions: distributions });

          if (res.id) {
            this.$emit("update:isVisible", false);
            this.$emit("itemAddedOrRemove", res);
            await this.$store.dispatch("success", "You have successfully added distribution!");
            this.isLoading = false;
          }
        }
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
        this.isLoading = false;
      }
    },
    async getDistributions() {
      this.isLoading = true;
      const res = await Trainplanet.listOrderSystemDistributions({ orderId: this.orderId });
      this.isLoading = false;
      if (!res.length) {
        return;
      }
      this.distributions = res.map((distribution) => {
        const response = distribution;
        let translation = response.translations.find((x) => x.locale === "en-SE");

        if (this.locale) {
          const hasTranslation = response.translations.find((x) => x.locale === this.locale);
          //title could be empty, use fallback if so
          if (hasTranslation && hasTranslation.title) {
            translation = hasTranslation;
          }
        }

        const prices = response.prices.filter((x) => x.currency === this.currency);

        if (response.flexiblePrice && prices.length === 0) {
          response.prices.push({
            currency: this.currency,
            vat: 0,
            amount: 0,
          });
        }

        if (translation && prices.length > 0) {
          response.title = `${translation.title} (+ ${prices.map((p) => p.amount).join("/")} ${prices[0].currency})`;
        } else {
          response.title = `${translation.title}`;
        }
        const isRegistered = this.registeredDistributions.find((x) => x.id === response.id);
        if (isRegistered) {
          return;
        }

        return response;
      });
    },
  },
};
</script>
