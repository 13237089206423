<template>
  <v-row justify="center">
    <v-dialog v-model="dialogVisible" style="overflow-x: auto" max-width="500">
      <v-card>
        <v-toolbar class="v-sheet theme--dark v-toolbar secondary">
          <v-btn v-if="selectedItemType" dark icon @click="resetSelectedItemType()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="closeAddItemModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row class="mt-4" v-if="!selectedItemType">
            <v-col cols="12">
              <h3>Please select the item type below you want to add.</h3>
            </v-col>
            <v-col cols="12">
              <span>
                <v-tooltip :disabled="canUseGiftcard" color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-btn
                        style="background-color: rgb(112, 163, 127); border-color: rgb(112, 163, 127)"
                        @click="handleAddItemTypeClick(1)"
                        :disabled="!canUseGiftcard"
                        class="mr-2 white--text"
                        >Gift Card
                      </v-btn>
                    </span>
                  </template>
                  <span>Tenant {{ tenantName }} is not allowed to sell gift cards.</span>
                </v-tooltip>
              </span>
              <v-btn
                style="background-color: rgb(93, 145, 232); border-color: rgb(93, 145, 232)"
                @click="handleAddItemTypeClick(2)"
                class="mr-2 white--text"
                >Article
              </v-btn>
              <v-btn
                style="background-color: rgb(134, 146, 197); border-color: rgb(134, 146, 197)"
                @click="handleAddItemTypeClick(3)"
                class="mr-2 white--text"
                >Distribution
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!-- Slot for Gift Card Form -->
            <v-col cols="12" v-if="selectedItemType === 1">
              <slot name="gift-card"></slot>
            </v-col>
            <!-- Slot for Article Form-->
            <v-col cols="12" v-if="selectedItemType === 2">
              <slot name="article"></slot>
            </v-col>
            <!-- Slot for Distribution Form -->
            <v-col cols="12" v-if="selectedItemType === 3">
              <slot name="distribution"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AddNewItemModal",
  data() {
    return {
      selectedItemType: null,
      title: "Add New Item",
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    canUseGiftcard: {
      type: Boolean,
      default: false,
    },
    tenantName: {
      type: String,
      default: "",
    },
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        setTimeout(() => {
          this.resetSelectedItemType();
        }, 200);
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        // this is a fix to prevent visual glitch when closing modal
        setTimeout(() => {
          this.selectedItemType = null;
        }, 300);
        return this.isVisible;
      },
      set(value) {
        this.$emit("update:isVisible", value);
      },
    },
  },
  methods: {
    closeAddItemModal() {
      this.$emit("update:isVisible", false);
    },
    handleAddItemTypeClick(itemType) {
      switch (itemType) {
        case 1:
          this.title = "Add New Gift Card";
          break;
        case 2:
          this.title = "Add New Article";
          break;
        case 3:
          this.title = "Add New Distribution";
          break;
        default:
          break;
      }
      this.selectedItemType = itemType;
    },
    resetSelectedItemType() {
      this.selectedItemType = null;
      this.title = "Add New Item";
    },
  },
};
</script>
