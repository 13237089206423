<template>
  <div>
    <header class="theme--light v-navigation-drawer elevation-1" style="height: 50px; border-radius: 5px">
      <div class="v-toolbar__content" style="height: 50px; padding: 17px">
        <div class="v-toolbar__title d-flex justify-space-between align-center" style="width: 100%">
          <div class="d-flex py-5">
            <h4 class="black--text d-flex justify-center align-center">
              <span class="mango-red mr-1">Items </span>({{ items.length }})
            </h4>
            <div
              v-if="orderId && !orderFullyPaid && canUseGiftcard && !isGiftCardInItems"
              class="gift-card"
              style="height: 30px">
              <v-text-field
                v-model.trim="giftCardCode"
                label="Gift Card Code"
                class="gift-card-input"
                height="30px"
                hide-details
                outlined
                dense />
              <v-btn @click="useGiftCardCode" :loading="loading" height="auto" color="accent">Use Gift Card</v-btn>
            </div>
          </div>
          <div v-if="$route.name == 'orders-edit'" class="d-flex">
            <v-tooltip v-if="!showSelect.value" content-class="tooltip black" top>
              <template #activator="{ on }" style="width: 200px">
                <div style="display: flex" v-on="on">
                  <v-btn
                    v-if="showAddItemButton && !showSelect.value"
                    @click="handleAddItemClick"
                    :loading="loading"
                    small
                    class="success white--text mr-2">
                    Add
                  </v-btn>
                </div>
              </template>
              Add item(s) to the order
            </v-tooltip>

            <v-tooltip content-class="tooltip black" top v-if="showRemoveButton && !showSelect.value">
              <template #activator="{ on }" style="width: 200px">
                <div style="display: flex" v-on="on">
                  <v-btn
                    @click="setState('remove')"
                    :disabled="!removeItems.length"
                    :loading="loading"
                    class="indigo accent-2 white--text"
                    small>
                    <span>REMOVE</span>
                  </v-btn>
                </div>
              </template>
              <span>Remove item(s) from the order</span>
            </v-tooltip>

            <div v-if="!showSelect.value" class="vertical-divider" />

            <v-tooltip v-if="!showSelect.value" content-class="tooltip black" top>
              <template #activator="{ on }" style="width: 200px">
                <div style="display: flex" v-on="on">
                  <v-btn
                    v-if="!showSelect.value"
                    :loading="loading"
                    :disabled="!orderIsPaid"
                    @click="
                      clickedButton = `item`;
                      refundSpecialOrder({ orderId });
                    "
                    small
                    class="white--text mr-2"
                    color="rgb(134, 146, 197)">
                    SPECIAL REFUND
                  </v-btn>
                </div>
              </template>
              Refund Items with Special Reason
            </v-tooltip>

            <v-tooltip v-if="!showSelect.value" content-class="tooltip black" top>
              <template #activator="{ on }" style="width: 200px">
                <div style="display: flex" v-on="on">
                  <v-btn
                    v-if="!showSelect.value"
                    :loading="loading"
                    @click="
                      clickedButton = `item`;
                      setState('refund');
                    "
                    :disabled="!refundItems.length"
                    small
                    class="third white--text third mr-2">
                    REFUND
                  </v-btn>
                </div>
              </template>
              Refund item(s) from the order
            </v-tooltip>

            <v-tooltip v-if="!showSelect.value" content-class="tooltip black" top>
              <template #activator="{ on }" style="width: 200px">
                <div style="display: flex" v-on="on">
                  <v-btn
                    v-if="!showSelect.value"
                    :loading="loading"
                    @click="
                      clickedButton = `item_cancel`;
                      setState('cancel');
                    "
                    :disabled="!cancelItems.length"
                    small
                    class="danger white--text">
                    CANCEL
                  </v-btn>
                </div>
              </template>
              Cancel item(s) from the order
            </v-tooltip>

            <div style="display: flex">
              <v-btn
                v-if="showSelect.value"
                :disabled="!selectedItems.length && !selectedChildItems.length"
                :loading="loading"
                small
                class="accent-2 white--text mr-2"
                :class="dynamicStateActionColor"
                @click="handleAction">
                <span>{{ stateAction }}</span>
              </v-btn>
              <v-btn v-if="showSelect.value" small class="indigo accent-2 white--text" @click="cancelSelection">
                <span>ABONDON ACTION</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </header>

    <Customer :customer="customer" :inquiry-id="inquiryId" :order-id="orderId" />
    <v-data-table
      :headers="orderItemHeaders"
      v-model="selectedItems"
      :items="filteredItems"
      item-key="id"
      class="elevation-1 mt-3 mb-5"
      show-expand
      disable-pagination
      hide-default-footer
      item-selectable="selectable"
      :item-class="itemClass"
      :show-select="showSelect.value">
      <template v-slot:[`expanded-item`]="{ headers, item }">
        <td v-if="item.type === 'journey'" :colspan="headers.length">
          <v-timeline align-top dense>
            <v-timeline-item v-for="(trip, i) in item.itinerary" :key="i" small>
              <v-card :color="itineraryIsNrt(trip) ? 'primary lighten-4' : 'grey lighten-4'">
                <v-card-title class="text-subtitle-2">
                  <span>{{ trip.departure.datetime | datetime }} • {{ trip.departure.locationName }}</span>
                  <v-icon small style="margin: 0 10px">mdi-arrow-right</v-icon>
                  <span>{{ trip.arrival.datetime | datetime }} • {{ trip.arrival.locationName }}</span>
                  <v-spacer />
                  <span v-if="itineraryIsNrt(trip)" class="font-weight-bold">NRT Fare</span>
                </v-card-title>

                <v-card-subtitle>
                  <span>{{ trip.operator.name }} • {{ trip.product.name }}</span>
                  <span v-if="trip.vehicle.name || trip.transportId">
                    <v-icon small style="margin: 0 5px">mdi-slash-forward </v-icon>{{ trip.vehicle.name }}
                    {{ trip.transportId }}
                  </span>
                </v-card-subtitle>

                <v-card-text>
                  <div class="text--primary">
                    <v-list>
                      <v-list-item v-for="(passenger, i) in trip.passengers" :key="i">
                        <v-checkbox
                          v-if="showSelect.value"
                          v-model="selectedChildItems"
                          :value="passenger.externalItemReferences" />
                        <v-list-item-title>
                          <div class="name">
                            <span v-if="passenger.personalInfo">
                              {{ passenger.personalInfo.firstName }}
                              {{ passenger.personalInfo.lastName }}
                              <!-- <v-icon small style="margin: 0 5px"
                                          >mdi-slash-forward</v-icon
                                        > -->
                            </span>
                          </div>
                          <span>
                            <v-icon small style="margin: 0 5px">mdi-seat-recline-normal</v-icon>
                            {{ passenger.seatNumber || "-" }}
                          </span>

                          <span>
                            <v-icon small style="margin: 0 5px">mdi-train-car-passenger</v-icon>
                            {{ passenger.carriageNumber || "-" }}
                          </span>

                          <span v-if="passenger.ageGroup && passenger.ageGroup.name">
                            <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>
                            {{ passenger.ageGroup.name }}
                          </span>

                          <span v-if="passenger.comfort">
                            <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>
                            {{ getTripPassengerSeatDetailsString(passenger) }}
                          </span>

                          <span v-if="passenger.options && passenger.options.length > 0">
                            <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>
                            {{ passenger.options.join(", ") }}
                          </span>
                        </v-list-item-title>

                        <v-spacer></v-spacer>

                        <v-chip
                          v-if="passenger.status !== 'pending' && passenger.status !== 'completed'"
                          class="text-uppercase v-chipextra"
                          small
                          :style="{
                            color: passenger.status === 'cancelled' ? 'white' : 'auto',
                          }"
                          :color="getPassengerStatusColor(passenger.status)">
                          {{ passenger.status }}:
                          {{ passenger.cancelInformation.amount.plainText }}
                        </v-chip>

                        <span class="pr-4 pl-2" style="margin-right: 73px"
                          ><span>
                            {{ getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger) }}
                          </span>
                        </span>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </td>
        <td v-else-if="item.type === 'article' || item.type === 'distribution'" :colspan="headers.length">
          <!--burası-->
          <div class="my-5 secondary--text" v-if="item.description">
            {{ item.description }}
          </div>

          <v-container v-if="item.tickets && item.tickets.length > 0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Box Number</th>
                    <th class="text-left">Paper Number</th>
                    <th class="text-left">Used Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in item.tickets" :key="ticket.paperNumber">
                    <td>{{ ticket.boxNumber }}</td>
                    <td>{{ ticket.paperNumber }}</td>
                    <td>{{ ticket.usedDate }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </td>
        <td v-if="item.type === 'railpass'" :colspan="headers.length">
          <div class="py-4">
            <v-simple-table class="elevation-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left primary--text">Comfort</th>
                    <th class="text-left primary--text">Age Group</th>
                    <th class="text-left primary--text">Passenger</th>
                    <th class="text-left primary--text">Start Date</th>
                    <th class="text-left primary--text">Stop Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="item.name">
                    <td>{{ item.comfort.name }}</td>
                    <td>{{ item.ageGroup.name }}</td>
                    <td>
                      {{ item.passenger.firstName }}
                      {{ item.passenger.lastName }}
                    </td>
                    <td>{{ item.startDate }}</td>
                    <td>{{ item.stopDate }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </td>
        <td v-if="item.type === 'giftcard'" :colspan="headers.length">
          <div class="py-4">
            <v-simple-table class="elevation-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left primary--text">Purpose</th>
                    <th class="text-left primary--text">Code</th>
                    <th class="text-left primary--text">Expiry date</th>
                    <th class="text-left primary--text">Remaining Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="item.name">
                    <td>{{ item.purpose }}</td>
                    <td>{{ item.giftCard.code }}</td>
                    <td>{{ item.giftCard.expires }}</td>
                    <td>{{ item.giftCard.remainingAmount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </td>
      </template>

      <template v-slot:[`item.isPrinted`]="{ item }">
        <span v-if="isItemPrinted(item.id)">
          <v-icon>mdi-printer</v-icon>
        </span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div style="display: flex">
          <v-chip :color="getTypeColor(item.type)" class="text-capitalize" label small dark>
            {{ item.type }}
          </v-chip>
          <span class="ml-2" v-if="isItemPrinted(item.id)">
            <v-icon>mdi-printer</v-icon>
          </span>
          <span
            class="ml-2 d-flex align-center"
            v-if="item.isInterrailSeatReservation && item.type === 'journey' && item.type === 'railpass'">
            <img src="@/assets/interrail-logo.png" width="20" height="18" alt="" />
          </span>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getStatusColor(item.status)" v-if="item.status" class="text-capitalize" label small outlined>
          {{ item.status.replace("_", " ") }}
        </v-chip>
      </template>

      <template v-slot:[`item.itemdata`]="{ item }">
        <div v-if="item.type === 'journey'">
          <span class="font-weight-medium"
            >{{ item.departure.datetime | datetime }} • {{ item.departure.locationName }}</span
          >
          <v-icon class="mx-2" small>mdi-arrow-right</v-icon>
          <span class="font-weight-medium"
            >{{ item.arrival.datetime | datetime }} • {{ item.arrival.locationName }}</span
          >
        </div>

        <div v-else-if="item.type === 'railpass'">
          <span class="font-weight-medium">{{ item.name }}</span>
        </div>

        <div v-else-if="item.type === 'distribution'">
          <span class="font-weight-medium mr-2"
            >{{ item.title || item.slug }} • {{ item.minDeliveryDays }} - {{ item.maxDeliveryDays }} days</span
          >
          <v-chip
            v-if="item.trackingId"
            color="grey lighten-4"
            :href="getTrackingLink(item)"
            target="_blank"
            link
            small>
            <v-icon left>mdi-truck-delivery</v-icon>
            Track shipment
          </v-chip>
        </div>

        <div v-else-if="item.type === 'article'">
          <span class="font-weight-medium">{{ item.title }}</span>
        </div>

        <div v-else-if="item.type === 'giftcard'">
          <span class="font-weight-medium">{{ item.name }}</span>
        </div>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ getQuantity(item) }}
      </template>

      <template v-slot:[`item.refundValue`]="{ item }">
        <div class="font-weight-bold" v-if="item.cancelInformation.amount.amount">
          {{ item.cancelInformation.amount.plainText }}
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span class="font-weight-bold">
          {{ item.price.plainText }}
        </span>
      </template>

      <template v-slot:[`item.externalIds`]="{ item }">
        <span class="font-weight-bold"> {{ getReadableExternalIdsOfOrderItem(item) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-tooltip color="black" max-width="400" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  v-if="
                    ['giftcard', 'article', 'distribution'].includes(item.type) &&
                    $route.name == 'orders-edit' &&
                    !showSelect.value &&
                    !item.isPaid
                  "
                  v-model="item.lockedForRemoval"
                  @change="handleLockToggle(item.id, item.lockedForRemoval)"
                  class="lock"
                  hide-details />
              </div>
            </template>
            <span>{{ item.lockedForRemoval ? lockedMessage : unlockedMessage }}</span>
          </v-tooltip>
        </div>
        <GiftCardPdf
          v-if="item.type === 'giftcard' && item.isPaid && item.purpose === 'purchasing'"
          :disabled="item.status !== 'completed'"
          tooltipMessage="PDF access is not allowed for gift cards that are not completed."
          :giftCardCode="item.giftCard.code" />
        <v-btn
          class="ml-2 danger white--text"
          v-if="item.type === 'distribution' && item.trackingId"
          :loading="loading && clickedButton === `distribution_cancel_${item.id}`"
          @click="
            clickedButton = `distribution_cancel_${item.id}`;
            cancelDistribution({ orderId, itemId: item.id });
          "
          small>
          CANCEL TO RE-PRINT
        </v-btn>
      </template>
    </v-data-table>

    <!-- cancel order modal -->
    <v-dialog v-if="inquiryId" v-model="cancelModal" max-width="700px">
      <v-card :disabled="$store.state.loading">
        <v-flex class="mb-12"></v-flex>
        <base-material-card color="indigo lighten-2" title="Add cancel information">
          <v-card-text>
            <ValidationObserver ref="cancelObserver">
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="reason" rules="required">
                    <v-textarea
                      filled
                      dense
                      hide-details
                      label="Reason of cancellation"
                      v-model="editedItem.reason"
                      :error-messages="errors"
                      clearable />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="otherReason" rules="">
                    <v-textarea
                      filled
                      dense
                      hide-details
                      label="Other Reason of cancellation"
                      v-model="editedItem.otherReason"
                      :error-messages="errors"
                      clearable />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="amount" rules="required|numeric|min:0">
                    <v-text-field
                      type="number"
                      filled
                      dense
                      hide-details
                      label="amount of refund"
                      v-model="editedItem.amount"
                      :error-messages="errors"
                      clearable />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6"></v-col>
              </v-row>
            </ValidationObserver>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              v-if="
                item.type !== 'distribution' &&
                item.status !== 'pending' &&
                item.status !== 'completed' &&
                item.status !== 'refunded'
              "
              :loading="loading"
              color="danger"
              outlined
              @click="cancelModal = false">
              Not Now
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="$store.state.loading" @click="addCancelInfo" color="success"> Save </v-btn>
          </template>
        </base-material-card>
      </v-card>
    </v-dialog>
    <AddNewItemModal :tenantName="tenantName" :canUseGiftcard="canUseGiftcard" :is-visible.sync="addItemModalVisible">
      <template v-slot:gift-card>
        <GiftCardForm
          :order-id="orderId"
          :currency="baseCurrency"
          :is-visible.sync="addItemModalVisible"
          @itemAddedOrRemove="addedOrRemoveItem($event)" />
      </template>
      <template v-slot:article>
        <ArticleForm
          :order-id="orderId"
          :registered-articles="articles"
          :tenant-id="tenantId"
          :currency="baseCurrency"
          :locale="locale"
          :is-visible.sync="addItemModalVisible"
          @itemAddedOrRemove="addedOrRemoveItem($event)" />
      </template>
      <template v-slot:distribution>
        <DistributionForm
          :order-id="orderId"
          :registered-articles="articles"
          :tenant-id="tenantId"
          :currency="baseCurrency"
          :locale="locale"
          :is-visible.sync="addItemModalVisible"
          @itemAddedOrRemove="addedOrRemoveItem($event)" />
      </template>
    </AddNewItemModal>

    <SpecialRefund
      :is-visible.sync="refundItemModalVisible"
      :items="items"
      :printedItemIds="printedItemIds"
      :orderId="orderId"
      :bookings="bookings"
      :currency="baseCurrency"
      @itemRefund="itemRefund">
    </SpecialRefund>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Customer from "./Customer";
import AddNewItemModal from "../../../components/order/orderItems/addNewItemModal.vue";

import SpecialRefund from "../../../components/order/orderItems/SpecialRefund.vue";

import GiftCardForm from "../../../components/order/giftCard/giftCardForm.vue";
import ArticleForm from "../../../components/order/article/articleForm.vue";
import DistributionForm from "../../../components/order/distribution/distributionForm.vue";
import GiftCardPdf from "@/components/order/giftCard/GiftCardPdf.vue";

export default {
  name: "Items",
  components: {
    Customer,
    ValidationProvider,
    ValidationObserver,
    AddNewItemModal,
    SpecialRefund,
    GiftCardForm,
    ArticleForm,
    DistributionForm,
    GiftCardPdf,
  },
  props: {
    items: Array,
    customer: Object,
    bookings: Array,
    orderId: {
      required: false,
      default: null,
    },
    inquiryId: {
      required: false,
      default: false,
    },
    initial: {
      type: Function,
    },
    orderStatus: {
      required: false,
    },
    tenantId: {
      required: true,
      type: String,
    },
    tenantName: {
      required: true,
      type: String,
    },
    printedItemIds: {
      required: false,
      type: Array,
    },
    orderIsPaid: {
      required: false,
      type: Boolean,
    },
    locale: {
      required: false,
      type: String,
    },
    //TODO: pass the currency object instead of string
    baseCurrency: {
      required: true,
      type: String,
    },
    canUseGiftcard: {
      required: false,
      type: Boolean,
    },
    orderFullyPaid: {
      required: false,
      type: Boolean,
    },
  },
  watch: {
    selectedChildItems: {
      handler() {
        this.filteredItems.forEach((item) => {
          if (item.type === "journey") {
            const uniquePassengerReferences = item.itinerary
              .flatMap((trip) => trip.passengers.map((passenger) => passenger.externalItemReferences))
              .filter((value, index, self) => {
                const valueAsString = JSON.stringify(value);
                return self.findIndex((other) => JSON.stringify(other) === valueAsString) === index;
              });

            const selectedChildItemsAsString = this.selectedChildItems.map((item) => JSON.stringify(item));

            const allTripsChecked = uniquePassengerReferences.every((reference) =>
              selectedChildItemsAsString.includes(JSON.stringify(reference))
            );

            if (allTripsChecked) {
              if (!this.selectedItems.includes(item)) {
                this.selectedItems.push(item);
              }
            } else {
              const index = this.selectedItems.indexOf(item);
              if (index !== -1) {
                this.selectedItems.splice(index, 1);
              }
            }
          }
        });
      },
      deep: true,
    },
    selectedItems: {
      handler(newVal, oldVal) {
        const changedItems = [...newVal, ...oldVal].filter((item) => !newVal.includes(item) || !oldVal.includes(item));

        changedItems.forEach((item) => {
          if (item.type === "journey") {
            const uniquePassengerReferences = item.itinerary
              .flatMap((trip) => trip.passengers.map((passenger) => passenger.externalItemReferences))
              .filter((value, index, self) => {
                const valueAsString = JSON.stringify(value);
                return self.findIndex((other) => JSON.stringify(other) === valueAsString) === index;
              });

            uniquePassengerReferences.forEach((reference) => {
              const referenceAsString = JSON.stringify(reference);
              const index = this.selectedChildItems.findIndex((item) => JSON.stringify(item) === referenceAsString);

              if (newVal.includes(item)) {
                if (index === -1) {
                  this.selectedChildItems.push(reference);
                }
              } else {
                if (index !== -1) {
                  this.selectedChildItems.splice(index, 1);
                }
              }
            });
          }
        });
      },
      deep: true,
    },
    items: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  data: () => ({
    orderItemHeaders: [
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
      //{
      //  text: "",
      //  value: "isPrinted",
      //  sortable: false,
      //  width: "2px" // auto width
      //},
      {
        text: "Type",
        value: "type",
        sortable: false,
        width: "1%", // auto width
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        width: "1%", // auto width
      },
      {
        text: "Item",
        value: "itemdata",
        sortable: false,
      },
      {
        text: "Quantity",
        value: "quantity",
        align: "left",
        sortable: false,
      },
      {
        text: "Price",
        value: "price",
        align: "left",
        sortable: false,
        width: "100",
      },
      {
        text: "Refunded Total",
        value: "refundValue",
        align: "left",
        sortable: false,
        width: "100",
      },
      {
        text: "External IDs",
        value: "externalIds",
        align: "left",
        sortable: false,
      },
      {
        text: " ",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    cancelModal: false,
    editedItem: {},
    loading: false,
    clickedButton: "",
    addItemModalVisible: false,
    refundItemModalVisible: false,
    showAddItemButton: true,
    showRemoveButton: true,
    showSelect: {
      value: false,
      type: null,
    },
    selectedItems: [],
    selectedChildItems: [],
    ItemArray: [],
    giftCardCode: "",
    lockedMessage: "This item is locked, which means the customer cannot remove it from the order.",
    unlockedMessage: "This item is not locked, which means the customer can remove it from the order.",
  }),
  filters: {
    datetime(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
  },
  computed: {
    articles() {
      return this.items.filter((x) => x.type === "article");
    },
    cancelItems() {
      let itemsCopy = JSON.parse(JSON.stringify(this.items));
      return itemsCopy.filter((item) => {
        if (item.status === "cancelled") {
          return false;
        }
        return this.shouldFilterItem(item, true);
      });
    },
    refundItems() {
      let itemsCopy = JSON.parse(JSON.stringify(this.items));
      return itemsCopy.filter((item) => this.shouldFilterItem(item));
    },
    removeItems() {
      let itemsCopy = JSON.parse(JSON.stringify(this.items));
      return itemsCopy.filter((item) => !item.isPaid && item.type !== "journey" && item.type !== "railpass");
    },
    filteredItems() {
      switch (this.showSelect.type) {
        case "remove":
          return this.removeItems;
        case "refund":
          return this.refundItems;
        case "cancel":
          return this.cancelItems;
        default:
          return this.items;
      }
    },
    isGiftCardInItems() {
      return this.items.some((item) => item.type === "giftcard" && item.purpose === "purchasing");
    },
    stateAction() {
      switch (this.showSelect.type) {
        case "remove":
          return "CONFIRM REMOVING ITEMS";
        case "cancel":
          return "CONFIRM CANCELLING ITEMS";
        case "refund":
          return "CONFIRM REFUNDING ITEMS";
      }
    },
    dynamicStateActionColor() {
      switch (this.showSelect.type) {
        case "remove":
          return "indigo";
        case "cancel":
          return "danger";
        case "refund":
          return "third";
        default:
          return "indigo";
      }
    },
  },
  methods: {
    shouldFilterItem(item, isCancel = false) {
      if (
        !item ||
        item.category == "digital" ||
        !item.type ||
        !item.status ||
        !item.isPaid ||
        item.type === "article" ||
        (item.type === "distribution" && item.category === "digital" && item.status === "pending") ||
        item.status === "refunded" ||
        (item.type === "giftcard" && item.purpose === "using") ||
        !this.orderIsPaid
      ) {
        return false;
      }
      if (item.type === "journey" && item.itinerary) {
        item.itinerary = item.itinerary.filter((itinerary) => {
          if (!itinerary.passengers) {
            return false;
          }
          itinerary.passengers = itinerary.passengers.filter(
            (passenger) =>
              passenger.status !== "pending" &&
              passenger.status !== "refunded" &&
              (!isCancel || passenger.status !== "cancelled")
          );
          return itinerary.passengers.length > 0;
        });
        return item.itinerary.length > 0;
      }
      return true;
    },
    itemClass() {
      return "item-row";
    },
    async handleLockToggle(itemId, lockedForRemoval) {
      try {
        await Trainplanet.lockOrderItem(this.orderId, itemId);
        this.$store.dispatch("success", "Item lock state has been updated successfully.");
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message);
      }
    },
    itineraryIsNrt(itinerary) {
      // if only contains date information and not time information (e.g. "2022-02-02")
      return itinerary.departure.datetime.length <= 10 && itinerary.arrival.datetime.length <= 10;
    },
    getReadableExternalIdsOfOrderItem(item) {
      const ret = [];

      if (item.externalIds) {
        // Object.entries do not work for some reason, so we do it this way
        // https://stackoverflow.com/questions/49385066/the-strange-behavior-of-object-entries
        const numberOfExternalConnections = Object.keys(item.externalIds).length;

        for (const bookingId in item.externalIds) {
          const itemIds = item.externalIds[bookingId];
          const booking = this.bookings.find((booking) => booking.id === bookingId);

          if (!booking) {
            continue;
          }

          if (numberOfExternalConnections === 1 && itemIds.length === 1) {
            ret.push(booking.externalOrderId + itemIds[0].toString().padStart(4, "0")); // e.g. RZF3982T0011
          } else {
            ret.push(booking.externalOrderId);
          }
        }
      }

      return ret.join(",");
    },
    getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger) {
      const ret = [];

      if (passenger.externalIds) {
        const booking = this.bookings.find((booking) => booking.id === passenger.externalBookingId);

        if (booking) {
          for (const key in passenger.externalIds) {
            const itemId = passenger.externalIds[key];

            ret.push(booking.externalOrderId + itemId.toString().padStart(4, "0")); // e.g. RZF3982T0011
          }
        }
      }

      return ret.join(",");
    },
    isItemPrinted(itemIds) {
      if (!this.printedItemIds) return false;

      if (Array.isArray(itemIds)) {
        return itemIds.every((itemId) => this.printedItemIds.includes(itemId));
      }

      return this.printedItemIds.includes(itemIds);
    },
    async cancelDistribution({ orderId, itemId }) {
      this.loading = true;

      try {
        await Trainplanet.cancelDistribution(orderId, itemId);
        await this.$store.dispatch("success", "Distribution has been cancelled successfully.");
        this.initial();
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      }

      this.loading = false;
      this.clickedButton = "";
    },
    getTypeColor(type) {
      switch (type) {
        case "journey":
          return "#494968";
        case "railpass":
          return "#E7A813";
        case "giftcard":
          return "#70A37F";
        case "distribution":
          return "#8692c5";
        case "article":
          return "#5d91e8";
        default:
          return "secondary";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "pending":
          return "amber";
        case "completed":
          return "green";
        case "cancelled":
          return "danger";
        case "partially_cancelled":
          return "warning";
        case "partially_credited":
          return "warning";
        case "partially_refunded":
          return "warning";
        case "refunded":
          return "warning";
        case "credited":
          return "warning";
        default:
          return "grey";
      }
    },
    getPassengerStatusColor(status) {
      switch (status) {
        case "cancelled":
          return "danger";
        case "credited":
          return "green";
        case "refunded":
          return "warning";
        default:
          return "grey";
      }
    },
    getQuantity(item) {
      switch (item.type) {
        case "journey":
          return item.itinerary.length;
        default:
          return 1;
      }
    },
    getTripPassengerSeatDetailsString(passenger) {
      const string = [];

      if (passenger.comfort && passenger.comfort.name) string.push(passenger.comfort.name);
      if (passenger.compartment && passenger.compartment.name) string.push(passenger.compartment.name);
      if (passenger.orientation && passenger.orientation.name) string.push(passenger.orientation.name);
      if (passenger.direction && passenger.direction.name) string.push(passenger.direction.name);

      return string.join(" • ");
    },
    getTrackingLink(item) {
      if (item.trackingLink) {
        return item.trackingLink;
      }

      return `https://tracking.postnord.com/se/?id=${item.trackingId}`;
    },
    setState(type) {
      this.showSelect = { value: true, type: type };
    },
    cancelSelection() {
      this.showSelect = { value: false, type: null };
      this.resetSelections();
    },

    resetSelections() {
      this.selectedItems = [];
      this.selectedChildItems = [];
    },

    handleAction() {
      switch (this.showSelect.type) {
        case "remove":
          this.confirmAndRemoveItems(this.orderId);
          break;
        case "cancel":
          this.cancelOrder(this.orderId);
          break;
        case "refund":
          this.refundOrder(this.orderId);
          break;
      }
    },

    addedOrRemoveItem(newValue) {
      this.ItemArray.push(newValue);
      this.$emit("itemChange", { type: "add", data: newValue });
    },

    itemRefund(res) {
      this.$emit("itemChange", { type: "refund", data: res });
    },

    payloadGenerator() {
      let payload = { items: [] };
      let tempItems = [...this.selectedItems];
      let selectedItemReferences = this.selectedItems
        .filter((item) => item.type === "journey")
        .flatMap((item) =>
          item.itinerary.flatMap((itinerary) =>
            itinerary.passengers.map((passenger) => passenger.externalItemReferences)
          )
        );

      if (this.selectedChildItems.length) {
        this.selectedChildItems.forEach((item) => {
          this.items.forEach((parentItem) => {
            if (parentItem.type === "journey") {
              parentItem.itinerary.forEach((itinerary) => {
                itinerary.passengers.forEach((passenger) => {
                  if (JSON.stringify(passenger.externalItemReferences) === JSON.stringify(item)) {
                    if (!tempItems.some((tempItem) => tempItem.id.includes(parentItem.id))) {
                      tempItems.push(parentItem);
                    }
                  }
                });
              });
            }
          });
        });
      }

      let filteredExternalReferences = this.selectedChildItems.filter((item) => {
        return !selectedItemReferences.includes(item);
      });

      for (let i = 0; i < tempItems.length; i++) {
        if (tempItems[i].type === "journey") {
          let externalReferences = tempItems[i].itinerary
            .flatMap((trip) => trip.passengers.map((passenger) => passenger.externalItemReferences))
            .filter((ref) => filteredExternalReferences.some((item) => JSON.stringify(item) === JSON.stringify(ref)));

          payload.items.push({
            itemId: tempItems[i].id,
            externalReferences: externalReferences.flat(),
          });
        } else {
          payload.items.push({
            itemId: tempItems[i].id,
          });
        }
      }
      return payload;
    },

    async confirmAndRemoveItems(orderId) {
      this.loading = true;
      let itemIds = this.selectedItems.map((item) => {
        return {
          type: item.type,
          name: item.name ?? item.description,
          price: item.price.plainText,
          title: item.title || null,
        };
      });
      let itemDetails = itemIds
        .map((item) => {
          return `<li >${item.type}-${item.type != "distribution" ? item.name : item.title}-${item.price}</li>`;
        })
        .join("");

      const confirmed = await this.$confirm(
        ` <h4>Are you sure you want to remove these items?</h4>
                 <ul style="margin-top:10px">${itemDetails}</ul>`,
        {
          color: "primary",
          title: "Remove Item(s)",
        }
      );
      if (confirmed && itemIds.length > 0) {
        try {
          const res = await Trainplanet.deleteItems(orderId, {
            itemIds: this.selectedItems.map((item) => item.id),
          });

          await this.$store.dispatch("success", "Item(s) successfully removed");
          this.showRemoveButton = true;
          this.$emit("itemChange", { type: "delete", data: res });
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message);
          console.error("Error removing items:", error);
        } finally {
          this.loading = false;
          this.cancelSelection();
        }
      } else {
        this.loading = false;
        this.cancelSelection();
      }
    },

    handleAddItemClick() {
      this.addItemModalVisible = true;
    },

    refundSpecialOrder() {
      this.items.forEach((item) => {
        if (item.type != "journey") {
          item.itemRefundAmount = null;
        } else {
          item.itinerary.forEach((itinerary) => {
            itinerary.passengers.forEach((passengers) => {
              passengers.itemRefundAmount = null;
            });
          });
        }
      });
      this.refundItemModalVisible = true;
    },

    async refundOrder(orderId) {
      this.loading = true;

      const payload = this.payloadGenerator();

      const res = await Trainplanet.getRefundAmount(orderId, payload)
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
      const amount = res?.refundAmount?.amount;

      if (res?.refundAmount) {
        this.$confirm(
          `
           <div>Are you sure to refund this order?</div>
           <h2 style="margin-top:10px">${amount + " " + res?.refundAmount?.currency}</h2>
          `,
          {
            color: "primary",
            title: "Refund Order",
          }
        )
          .then(async (res) => {
            if (!res) return;

            await this.$store.dispatch("loading", true);

            await Trainplanet.CancelAndRefund(orderId, payload)
              .then((response) => {
                this.$store.dispatch("success", "Item has been refunded.");
                this.initial();
              })
              .catch((error) => {
                this.$store.dispatch("error", error.response.data.message);
                this.loading = false;
              })
              .finally(() => {
                this.cancelModal = false;
                this.$store.dispatch("loading", false);
                this.loading = false;
              });
          })
          .catch((error) => {
            this.$store.dispatch("error", error.response.data.message);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
            this.cancelSelection();
          });
      }
      this.loading = false;
    },

    async cancelOrder(orderId) {
      this.loading = true;

      const payload = this.payloadGenerator();

      let res;
      try {
        res = await Trainplanet.getRefundAmount(orderId, payload);
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message);
        this.loading = false;
        return;
      }
      this.loading = false;

      const amount = res?.refundAmount?.amount;
      const itemIds = payload.items.map((item) => item.itemId);
      const warningMessage = this.isItemPrinted(itemIds)
        ? '<div>Are you sure to cancel this order?</div><h5 style="margin-top:10px;color:tomato">This order will be refunded when customer physically submit ticket.</h5>'
        : "<div>Are you sure to cancel this order?</div>";
      this.$confirm(
        warningMessage +
          `<h2 style="margin-top:10px">${amount + " " + res?.refundAmount?.currency}</h2>
              `,
        {
          color: "primary",
          title: "Cancel Order",
        }
      )
        .then(async (res) => {
          if (!res) return;

          await this.$store.dispatch("loading", true);

          await Trainplanet.cancelItems(orderId, payload)
            .then((response) => {
              this.$store.dispatch("success", "Item has been cancelled.");
              this.initial();
            })
            .catch((error) => {
              this.$store.dispatch("error", error.response.data.message);
            })
            .finally(() => {
              this.cancelModal = false;
              this.$store.dispatch("loading", false);
              this.loading = false;
            });
        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.cancelSelection();
        });
      this.loading = false;
    },

    async addCancelInfo() {
      this.$refs.cancelObserver.validate().then(async (res) => {
        if (!res) return;

        await this.$store.dispatch("loading", true);

        await Trainplanet.addCancelInfo(this.orderId, this.editedItem.itemId, this.editedItem)
          .then((response) => {
            this.$store.dispatch("success", "Item has been updated.");
            const input = {
              status: response.data.code,
              message: response.data.data[0],
            };
            // call to create new inquiry event and emit it to inquiry/Edit.vue
            this.createEvent(input);
          })
          .catch((error) => {
            this.$store.dispatch("error", error.response.data.message);
          })
          .finally(() => {
            this.cancelModal = false;
            this.$store.dispatch("loading", false);
            this.loading = false;
          });
      });
    },

    createEvent(data) {
      Trainplanet.createInquiryEvent(this.inquiryId, Object.assign({}, data))
        .then((res) => {
          this.$emit("addEvent", res);
        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        });
    },
    async useGiftCardCode() {
      this.loading = true;
      const body = {
        codes: [this.giftCardCode.trim()],
      };
      try {
        await Trainplanet.useGiftCard(this.orderId, body);
        this.$store.dispatch("success", "Gift card code has been used.");
        this.initial();
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message);
      } finally {
        this.loading = false;
        this.giftCardCode = "";
      }
    },
    isCreatePage() {
      // Do not show add item button in order create page.
      this.showAddItemButton = !this.$route.path.includes("orders/create"); //todo or in edit order page
    },

    /**
     * Determine whether the action will appear in the actions menu based on item's type and category.
     *
     * @param {Object} item
     * @param {string} action
     * @returns {boolean}
     */
    isActionVisibleInItemActionMenu(item, action) {
      switch (action) {
        case "cancelToRePrint":
          return item.type === "distribution" && item.category === "physical";

        case "cancel":
        case "refund":
          return (
            ["journey", "railpass"].includes(item.type) ||
            (item.type === "distribution" && item.category === "physical") ||
            (item.type === "giftcard" && item.purpose === "purchasing")
          );

        case "manualRefund":
          return (
            ["journey", "railpass", "article"].includes(item.type) ||
            (item.type === "distribution" && item.category === "physical") ||
            (item.type === "giftcard" && item.purpose === "purchasing")
          );

        case "delete":
          return ["article", "distribution", "giftcard"].includes(item.type);

        default:
          throw new Error("Unsupported action: " + action);
      }
    },
    /**
     * Determine whether the action will be enabled in the action menu based on the item
     */
    isActionEnabledInItemActionMenu(item, action) {
      switch (action) {
        case "cancelToRePrint":
          return item.isPaid && item.status === "completed";

        case "cancel":
          return item.isPaid && item.status !== "cancelled" && item.status !== "refunded";

        case "refund":
          return item.isPaid && item.status !== "refunded";

        case "manualRefund":
          return (
            ["journey", "railpass", "article"].includes(item.type) ||
            (item.type === "distribution" && item.category === "physical") ||
            (item.type === "giftcard" && item.purpose === "purchasing")
          );

        case "delete":
          return ["article", "distribution", "giftcard"].includes(item.type);

        default:
          throw new Error("Unsupported action: " + action);
      }
    },
  },
  created() {
    this.isCreatePage(); // If create order page, update add item button visibility.
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  min-width: 130px;
  justify-content: center;
}
.v-chipextra {
  min-width: 135px !important;
  justify-content: center;
}
.v-chipextra span {
  padding-right: 20px;
}
.name {
  min-width: 200px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status {
  text-align: center;
}
.priceValue {
  min-width: 80px;
  width: 80px;
}
.gift-card {
  display: flex;
  justify-content: flex-start;
  padding-left: 2rem;
  overflow: visible;
  max-width: 400px;
}

::v-deep .v-input--is-disabled {
  background-color: transparent;
  opacity: 0.5;
}

@media screen and (max-width: 992px) {
  .gift-card {
    max-width: 100% !important;
  }
}

.gift-card-input {
  margin-right: 16px !important;

  .v-input__slot {
    min-height: auto !important;
  }

  label {
    top: 6px !important;
  }
}

.vertical-divider {
  background-color: #e0e0e0;
  width: 1px;
  height: 28px;
  margin: 0 12px;
}

::v-deep .item-row {
  height: 68px;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
