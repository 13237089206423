<template>
  <v-form ref="form" v-model="formValid">
    <v-expansion-panels v-if="customer" :value="0" accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red">
          Customer Information
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.firstName" v-model="customer.firstName" label="Firstname"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="customer.lastName" v-model="customer.lastName" label="Lastname" hide-details="auto"
                outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field :value="customer.phone" v-model="customer.phone" label="Phone" hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="customer.email" :rules="validate.email" label="Email" hide-details="auto"
                outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn class="accent" @click="update" :loading="loading">Update</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="customer.delivery">
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red">Shipping
          address</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.firstName" v-model="customer.delivery.firstName" label="Firstname"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.lastName" v-model="customer.delivery.lastName" label="Lastname"
                hide-details="auto" outlined></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.phone" v-model="customer.delivery.phone" label="Phone"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="customer.delivery.email" label="Email" hide-details="auto" outlined
                required></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="customer.delivery.address" label="Address" hide-details="auto"
                outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field :value="customer.delivery.address2" v-model="customer.delivery.address2" label="Address 2"
                hide-details="auto" outlined></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.region" v-model="customer.delivery.region" label="Region"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.postalCode" v-model="customer.delivery.postalCode"
                label="Postal Code" hide-details="auto" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.delivery.city" v-model="customer.delivery.city" label="City"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="customer.delivery.country" :items="countries" clearable hide-details
                return-object label="Country" outlined>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn class="accent" @click="update" :loading="loading">Update</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="customer.billing">
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red">
          Billing address
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <v-text-field :value="customer.billing.firstName" v-model="customer.billing.firstName" label="Firstname"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="customer.billing.lastName" v-model="customer.billing.lastName" label="Lastname"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field :value="customer.billing.phone" v-model="customer.billing.phone" label="Phone"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="customer.billing.email" label="Email" hide-details="auto" outlined
                required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field :value="customer.billing.address" v-model="customer.billing.address" label="Address"
                hide-details="auto" outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field :value="customer.billing.address2" v-model="customer.billing.address2" label="Address 2"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field :value="customer.billing.region" v-model="customer.billing.region" label="Region"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field :value="customer.billing.postalCode" v-model="customer.billing.postalCode"
                label="Postal Code" hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field :value="customer.billing.city" v-model="customer.billing.city" label="City"
                hide-details="auto" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-autocomplete v-model="customer.billing.country" :items="countries" clearable hide-details return-object
                label="Country" outlined>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn class="accent" @click="update" :loading="loading">Update</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red">
          Payment information</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field v-model="customerPayment.bankName" label="Bank Name" outlined hide-details />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="customerPayment.clearingNumber" label="Clearing Number" hide-details outlined />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="customerPayment.accountNumber" label="Account Number" hide-details outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn @click="update" class="accent" :loading="loading">Update</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import common from "@/mixins/common";
import { validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import countryList from "../../../static/countries.json";
export default {
  name: "Customer",
  props: {
    customer: Object,
    orderId: {
      required: true,
      default: null,
    },
    inquiryId: {
      required: false,
      default: false,
    },
  },
  mixins: [common],
  data: () => ({
    formValid: false,
    loading: false,
    customerPayment: {},
    countries: countryList,
    validate: {
      greater: validator.greaterZeroCheck(),
      input: validator.required(),
      phone: validator.phone(),
      postalcode: validator.postalCode(),
      email: validator.email(),
      orderNumber: validator.orderNumber(),
      select: validator.select(),
    },
  }),
  mounted() { },
  methods: {
    async update() {
      if (this.$refs[`form`].validate()) {
        const confirm = await this.$confirm(
          "You are about to change the order information now, do you want to continue?",
          {
            color: "primary",
            title: "Update Information",
          }
        );
        if (confirm) {
          this.loading = true;
          let id = this.orderId;
          //BURASI ACCOUNT İLE DEĞİŞECEK
          if (this.customer?.accountInfo?.id) {

            this.customer['account'] = {
              id: this.customer.accountInfo.id
            }
          }
          delete this.customer.accountInfo
          if (this.customer.payment) {
            this.customer.payment = this.customerPayment;
          }
          if (this.customer.delivery.country != null) {
            let countryCode = this.customer.delivery.country.value
              ? this.customer.delivery.country.value
              : this.customer.delivery.country;
            this.customer.delivery.country = countryCode;
          }
          if (this.customer.billing.country != null) {
            let countryCode = this.customer.billing.country.value
              ? this.customer.billing.country.value
              : this.customer.billing.country;
            this.customer.billing.country = countryCode;
          }
          await Trainplanet.updateOrder(id, {
            customer: this.customer,
          })
            .then((res) => {
              this.onSuccess(`Customer information Updated`);
              this.$emit("updated");
            })
            .catch((error) => {
              if (error.message) {
                this.onError(error.message);
              }
            })
            .finally((r) => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      } else {
        this.onError("Please check all required fields");
      }
    },
  },
  created() {
    this._customer = this.customer;
    if (this.customer && this.customer.payment) {
      this.customerPayment = this.customer.payment;
    }
  },
};
</script>
