var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "mt-3"
  }, [_vm._v(" Select the articles you want to add to the order. ")]), _c('span', [_vm._v("You may only select the articles provided by tenant which is registered in order. ")]), _c('v-form', {
    ref: "articleForm",
    attrs: {
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.form.valid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid", $$v);
      },
      expression: "form.valid"
    }
  }, [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.articles.length > 0 ? _c('v-combobox', {
    attrs: {
      "label": "Articles",
      "items": _vm.articles,
      "item-text": "title",
      "item-value": "slug",
      "small-chips": "",
      "hide-details": "",
      "multiple": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent,
          selected = _ref.selected;
        return [item === Object(item) ? _c('v-chip', _vm._b({
          attrs: {
            "input-value": selected,
            "label": "",
            "small": ""
          }
        }, 'v-chip', attrs, false), [_c('span', {
          staticClass: "pr-2"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" $delete ")])], 1) : _vm._e()];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Add additional articles to the order. For flexible priced articles, you can leave the price boxes below empty and the system will choose the correct price depending on if the order is international or domestic.")])])];
      },
      proxy: true
    }], null, false, 843611546),
    model: {
      value: _vm.form.articles,
      callback: function ($$v) {
        _vm.$set(_vm.form, "articles", $$v);
      },
      expression: "form.articles"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_vm.form.articles.length > 0 ? _c('v-list', _vm._l(_vm.form.articles, function (item, i) {
    return _c('v-list-item', {
      key: i,
      class: 'd-flex align-center py-1 px-3 rounded border999 ' + (i < 1 ? 'mt-2' : 'mt-4')
    }, [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.title)
      }
    }), !item.flexiblePrice ? _c('b', {
      staticStyle: {
        "font-size": "20px",
        "width": "100px"
      }
    }, [_vm._v(_vm._s(item.prices.filter(function (r) {
      return r.currency === _vm.currency;
    }).map(function (p) {
      return p.amount;
    }).join('/')) + " " + _vm._s(_vm.currency) + " ")]) : _vm._e(), item.flexiblePrice ? _c('v-text-field', {
      staticClass: "font-weight-bold",
      attrs: {
        "disabled": !item.flexiblePrice,
        "hide-details": "",
        "label": item.prices.filter(function (r) {
          return r.currency === _vm.currency;
        }).map(function (p) {
          return p.amount;
        }).join('/'),
        "hint": "Price",
        "outlined": "",
        "type": "number",
        "dense": ""
      },
      model: {
        value: item.setPrice,
        callback: function ($$v) {
          _vm.$set(item, "setPrice", _vm._n($$v));
        },
        expression: "item.setPrice"
      }
    }) : _vm._e(), item.flexiblePrice ? _c('span', {
      staticClass: "currency",
      staticStyle: {
        "position": "absolute",
        "right": "30px",
        "top": "50%",
        "transform": "translateY(-50%)"
      }
    }, [_c('b', {
      staticStyle: {
        "font-size": "16px"
      }
    }, [_vm._v(_vm._s(_vm.currency))])]) : _vm._e()], 1);
  }), 1) : _vm._e()], 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-3",
    staticStyle: {
      "margin-left": "390px"
    },
    attrs: {
      "loading": _vm.isLoading,
      "disabled": !_vm.isValid,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.saveArticles();
      }
    }
  }, [_vm._v(" Save ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }