<template>
  <div v-show="showForm">
    <v-form ref="giftCardForm" v-model="form.valid" :disabled="isLoading">
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field v-model="giftCard.title1" label="Title Line 1" hide-details="auto" outlined></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="giftCard.title2" label="Title Line 2" hide-details="auto" outlined></v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="amount" style="position: relative">
            <v-text-field
              v-model="giftCard.amount"
              label="Price Amount"
              hide-details
              :rules="validate.greater"
              outlined
              type="number"
              @input="checkInput"></v-text-field>
            <span style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%)" class="currency">
              <b style="font-size: 20px">{{ currency }}</b>
            </span>
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="giftCard.quantity"
            label="Quantity"
            :rules="validate.greater"
            hide-details
            outlined
            type="number"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="giftCard.message" outlined label="Message" hide-details clearable />
        </v-col>
      </v-row>
    </v-form>

    <v-btn
      :loading="isLoading"
      class="mt-2"
      color="accent"
      style="margin-left: 390px"
      :disabled="!isValid"
      @click="saveOrUpdateGiftCard()">
      Save
    </v-btn>
  </div>
</template>
<script>
import { validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api.js";

export default {
  name: "GiftCardForm",
  data() {
    return {
      showForm: true,
      isValid: false,
      isLoading: false,
      form: {
        valid: false,
      },
      giftCard: {
        title1: "",
        title2: "",
        amount: 0,
        quantity: 0,
        message: "",
      },
      validate: {
        greater: validator.greaterZeroCheck(),
      },
    };
  },
  props: {
    currency: {
      type: String,
      default: "",
    },
    orderId: {
      type: String,
      default: "",
    },
  },
  methods: {
    checkInput() {
      const min = 0;
      const max = 2147483647;
      this.isValid = this.giftCard.amount >= min && this.giftCard.amount <= max;
    },
    async saveOrUpdateGiftCard() {
      try {
        if (this.$refs[`giftCardForm`].validate()) {
          this.isLoading = true;
          const giftCards = [];

          for (let i = 0; i < this.giftCard.quantity; i++) {
            giftCards.push({
              ...this.giftCard,
              amount: parseInt(this.giftCard.amount),
            });
          }

          const res = await Trainplanet.addItems(this.orderId, {
            giftCards: giftCards,
            articles: [],
            distributions: [],
          });
          
          if (res.id) {
            this.$emit("update:isVisible", false);
            this.$emit("itemAddedOrRemove", res);
            await this.$store.dispatch("success", "You have successfully added gift card!");
            this.isLoading = false;
          }
        }
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      }
    },
  },
};
</script>
